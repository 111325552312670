import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactMarkdown from 'react-markdown';
import EventDate from './EventDate';
import EventTime from './EventTime';
import Tags from './Tags';
import './Event.less';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Event({ event, tickets=true, more=true, details=false, image=false, artsCouncil=false }) {
	const {
		date,
		dateDay,
		dateMonthAbbrev,
		fromTime,
		toTime,
		title,
		image: img,
		summary,
		description,
		location,
		url,
		tags,
		flags
	} = event;

	const navigate = useNavigate();

	const popover = (
		<Popover id="popover-basic">
			<Popover.Header as="h3">Join our mailing list</Popover.Header>
				<Popover.Body>
					There are no tickets to this event hosted by the
					Teachers' Club. To join the mailing list, email:<br />
					<a href="mailto:ansetdance.traditional.singers@gmail.com">
						ansetdance.traditional.singers@gmail.com
					</a>.  Entry to the club is free, but we ask everyone
					to make a small donation during the night to support
					hosting the event, and promoting traditional singing.
				</Popover.Body>
			</Popover>
		);

	const noClub = tags.indexOf('no-club') >= 0;
	return (
		<div className="h-event" id={date}>
			<div className="event-title">
				<EventDate
					day={dateDay}
					month={dateMonthAbbrev}
				/>
				<h1 className="p-name">{title}</h1>
				{!noClub && <EventTime
					date={date}
					fromTime={fromTime}
					toTime={toTime}
				/>}
			</div>
			<div>
				{image && img && (
					<div className="image-container">
						<img src={img} alt={title} />
					</div>
				)}
				<ReactMarkdown className="p-summary text-0">
					{details && description ? description : summary}
				</ReactMarkdown>
			</div>
			<div className="event-details">
				<span className="p-location">
					<Tags location={location} tags={tags} />
				</span>
				{ !noClub && fromTime && toTime &&
					<span className="start-at">
						{fromTime} - {toTime} (GMT)
					</span>
				}
				<a href={url} className="p-url">permalink</a>
				<div className="event-actions">
					{tickets && flags.tickets && (
						<OverlayTrigger trigger="click" placement="right" overlay={popover}>
							<Button variant="secondary">Tickets</Button>
						</OverlayTrigger>
					)}
					{ more && (
						<Button
							onClick={() => {
								if (more === true) {
									navigate(`/calendar/${date}`);

								} else {
									window.location = more;
								}
							}}
							variant="light"
						>
							Read more
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};
