import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import cn from 'classnames';
import contexts from '../../context/contexts';
import Event from './Event';
import EditableEvent from './EditableEvent';
import EditEvent from './EditEvent';
import Loading from '../../widgets/Loading';
import './Calendar.less';

const {
	useStore: useEventStore,
	useDispatch: useEventDispatch,
	makeDispatcher: makeEventDispatcher
} = contexts.events;

const {
	useStore: useLoginStore
} = contexts.login;

const debug = false;

export default function Calendar({ props }) {
	const eventDispatch = makeEventDispatcher(useEventDispatch());
	const { events } = useEventStore();
	const navigate = useNavigate();

	const { login } = useLoginStore();
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const [showConfirmDelete, setShowConfirmDelete] = useState(false);
	const [showConfirmDeleteAll, setShowConfirmDeleteAll] = useState(false);
	const handleClose = () => setShowConfirmDelete(false);
	const handleDeleteAllClose = () => setShowConfirmDeleteAll(false);
	const [updating, setIsUpdating] = useState(null);
	const [editEvent, showEditDialog] = useState(false);
	const [showAddEvent, setShowAddEvent] = useState(false);
	const handleAddEventClose = () => setShowAddEvent(false);
	const now = new Date();
	now.setHours(0);
	now.setMinutes(0);
	now.setSeconds(0);
	now.setMilliseconds(0);
	const [selectedDate, setSelectedDate] = useState(now);

	const log = debug ? console.log : () => {};

	useEffect(() => {
		if (!login.finishedLoading) {
			return setIsAuthenticated(false);
		}
		if (login.data.logged) {
			setIsAuthenticated(true);
		} else {
			setIsAuthenticated(false);
		}
	}, [login])

	useEffect(() => {
		log('use-effect-1');
		if (isAuthenticated) {
			eventDispatch({ type: 'FETCH_EVENTS_FULL' });
		} else {
			eventDispatch({ type: 'FETCH_EVENTS' });
		}
	}, []); // the [] is for when mounting only

	useEffect(() => {
		log('use-effect-2');
		setIsUpdating(null);
	}, [events && events.data]);

	useEffect(() => {
		log('use-effect-3');
		if (events.staleEvents) {
			if (isAuthenticated) {
				eventDispatch({ type: 'FETCH_EVENTS_FULL' });
			} else {
				eventDispatch({ type: 'FETCH_EVENTS' });
			}
		}
	}, [events.staleEvents === true])

	function onConvert(event, type) {
		const newEvent = {
			location: 'pipers-club',
			date: event.date,
			title: event.title,
			summary: 'Learn Irish traditional Set Dancing with Brooks Academy at Na Píobairí Uilleann on Henrietta Street. Everyone with an interest in traditional Irish dance is welcome! Beginners are welcome, but there is an annual fee of €40.00.',
			description: '',
			fromTime: '8pm',
			toTime: '11pm',
			tags: event.tags,
			flags: event.flags
		};
		if (type === 'setdance-lessons') {
			newEvent.title = 'Set Dancing Lessons';
			newEvent.tags = [
				'setdance-lessons'
			];
		} else if (type === 'special-event') {
			newEvent.title = 'Special guest';
			newEvent.tags = [
				'special-event'
			];
		} else if (type === 'ceili') {
			newEvent.location = '';
			newEvent.title = 'Céilí';
			newEvent.summary = 'TODO: add info about the céilí.'
			newEvent.tags = [
				'ceili'
			];
		} else if (type === 'none') {
			newEvent.location = '';
			newEvent.title = 'Closed';
			newEvent.summary = 'Brooks Academy is closed.'
			newEvent.tags = [
				'no-club'
			];
		}
		setIsUpdating(event.date);

		log('updating event', newEvent);
		eventDispatch({
			type: 'UPDATE_EVENT',
			payload: {
				event: newEvent
			}
		});
	}

	function handleDeleteEvent(event) {
		log('deleting event', event);
		eventDispatch({
			type: 'DELETE_EVENT',
			payload: {
				event
			}
		});
		setShowConfirmDelete(false);
	}

	function handleDeleteAll() {
		log('delete all events');
		eventDispatch({
			type: 'DELETE_ALL_EVENTS',
			payload: {
			}
		});
		setShowConfirmDeleteAll(false);
	}

	function handleAddEvent(date) {
		log('add event');
		const eventDate = [
			date.getFullYear(),
			String(date.getMonth() + 1).padStart(2, '0'),
			String(date.getDate()).padStart(2, '0'),
		].join('-');
		const newEvent = {
			location: 'pipers-club',
			date: eventDate,
			title: 'Set Dancing Lessons',
			summary: 'Learn Irish traditional Set Dancing with Brooks Academy at Na Píobairí Uilleann on Henrietta Street. Everyone with an interest in traditional Irish dance is welcome! Beginners are welcome, but there is an annual fee of €40.00.',
			description: '',
			fromTime: '8pm',
			toTime: '11pm',
			tags: ['setdance-lessons'],
			flags: []
		};
		eventDispatch({
			type: 'CREATE_EVENT',
			payload: {
				event: newEvent
			}
		});
		setShowAddEvent(false);
	}

	function showConfirmDeleteModal(event) {
		return (
			<Modal show={!!event} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						Delete <b>{event.dateDay} {event.dateMonthAbbrev}</b>?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button variant="danger" onClick={() => handleDeleteEvent(event)}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	function showConfirmDeleteAllModal() {
		return (
			<Modal show={!!showConfirmDeleteAll} onHide={handleDeleteAllClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						Regenerate event calendar?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete all events and regenerate the caledar?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleDeleteAllClose}>
						Cancel
					</Button>
					<Button variant="danger" onClick={() => handleDeleteAll()}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	function showAddEventModal() {
		return (
			<Modal show={!!showAddEvent} onHide={handleAddEventClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						Add event
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h2>Event date</h2>
					<DayPicker
						mode="single"
						selected={selectedDate}
						onSelect={setSelectedDate}
						footer={
							selectedDate ? `Selected: ${selectedDate.toLocaleDateString()}` : "Pick a day."
						}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleAddEventClose}>
						Cancel
					</Button>
					<Button variant="primary" onClick={
						() => handleAddEvent(selectedDate)
					}>
						Add
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	log('calendar', {
		updating: !!updating,
		finishedLoading: !!(events && events.finishedLoading),
		staleEvents: !!(events && events.staleEvents),
		count: (events && events.data && events.data.length) || 0,
		authenticated: isAuthenticated,
		events
	});

	let all;
	if (events.finishedLoading && !events.data.length) {
		all = (
			<div className="box-content box">
				No events.
			</div>
		);
	} else if (!events.finishedLoading) {
		all = <Loading title="Loading events..." />;
	} else {
		all = events.data.map(event => {
			if (isAuthenticated) {
				const isUpdating = event.date === updating;
				return (
					<div key={event.date} className={cn('box-content', 'box', { updating: isUpdating })}>
						<EditableEvent
							event={event}
							onConvert={onConvert}
							onDelete={() => setShowConfirmDelete(event)}
							onEdit={() => {
								navigate(`/calendar/${event.date}/edit`)
							}}
						/>
					</div>
				);
			} else {
				return (
					<div key={event.date} className="box-content box">
						<Event image more={false} event={event} />
					</div>
				);
			}
		});
	}

	return (
		<div className="calendar">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
				Brooks Academy - Calendar
				</title>
			</Helmet>
			<h1>Event calendar</h1>
			{ isAuthenticated && events.finishedLoading && (
				<div className="box-content">
					<Button variant="danger" onClick={() => setShowConfirmDeleteAll(true)}>
						<span className="bi-trash-fill"></span>
						{events.data.length ? "Delete all and regenerate" : "Generate event calendar"}
					</Button>
					{' '}
					<Button variant="light" onClick={() => setShowAddEvent(true)}>
						<span className="bi-calendar-plus"></span>
						Add new event
					</Button>
				</div>
			)}
			<div>
				{all}
			</div>

			{showConfirmDeleteModal(showConfirmDelete)}
			{showConfirmDeleteAllModal()}
			{showAddEventModal()}

			<Modal size="lg" show={!!editEvent} onHide={() => {}}>
				<Modal.Header closeButton>
					<Modal.Title>
						Editing <b>{editEvent.dateDay} {editEvent.dateMonthAbbrev}</b>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditEvent event={editEvent} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => showEditDialog(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => showEditDialog(false)}>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
