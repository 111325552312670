import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Helmet } from 'react-helmet';
import Event from './Event';
import contexts from '../../context/contexts';
import './CalendarEvent.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.event;

/**
 * bound to route: /calendar/:date
 */
export default function CalendarEvent({ props }) {
	const dispatch = makeDispatcher(useDispatch());
	const { event } = useStore();
	const params = useParams();

	useEffect(() => {
		// Every time
		dispatch({ type: 'FETCH_EVENT', payload: params.date });
	}, []); // the [] is for when mounting only

	if (!event.data) {
		return null;
	}

	return (
		<div className="calendar-event">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - {event.data.title}
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/calendar">
						<Breadcrumb.Item>Calendar</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						{event.data.dateDay} {event.data.dateMonthAbbrev} {event.data.dateYear}
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<Event
					key={event.data.date}
					event={event.data}
					tickets={true}
					more={false}
					summary={false}
					details
					image
				/>
			</div>
		</div>
	);
}
